import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`S01E01 - First commit`}</h1>
    <ul>
      <li parentName="ul">{`Släpptes: 2019-12-16`}</li>
    </ul>
    <h2>{`Introduktion`}</h2>
    <p>{`Vi introducerar oss själva och podden.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Firefox 15 år - `}<a parentName="li" {...{
          "href": "https://blog.mozilla.org/blog/2013/04/02/15-years-of-a-better-web/"
        }}>{`https://blog.mozilla.org/blog/2013/04/02/15-years-of-a-better-web/`}</a></li>
      <li parentName="ul">{`State of Mozilla 2018 - `}<a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/foundation/annualreport/2018/"
        }}>{`https://www.mozilla.org/en-US/foundation/annualreport/2018/`}</a></li>
      <li parentName="ul">{`Firefox version 70/71 - `}<a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/firefox/releases/"
        }}>{`https://www.mozilla.org/en-US/firefox/releases/`}</a></li>
      <li parentName="ul">{`Chrome version 79 - `}<a parentName="li" {...{
          "href": "https://chromereleases.googleblog.com/2019/12/stable-channel-update-for-desktop.html"
        }}>{`https://chromereleases.googleblog.com/2019/12/stable-channel-update-for-desktop.html`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Googles produktcykel - `}<a parentName="li" {...{
          "href": "https://gcemetery.co/"
        }}>{`https://gcemetery.co/`}</a></li>
      <li parentName="ul">{`OnePlus uppgifter har läckt - `}<a parentName="li" {...{
          "href": "https://forums.oneplus.com/threads/security-notification.1144088/"
        }}>{`https://forums.oneplus.com/threads/security-notification.1144088/`}</a></li>
      <li parentName="ul">{`Disney-plus konton hackade - `}<a parentName="li" {...{
          "href": "https://www.zdnet.com/article/thousands-of-hacked-disney-accounts-are-already-for-sale-on-hacking-forums/"
        }}>{`https://www.zdnet.com/article/thousands-of-hacked-disney-accounts-are-already-for-sale-on-hacking-forums/`}</a></li>
      <li parentName="ul">{`Magic: The Gathering "hackat" - `}<a parentName="li" {...{
          "href": "https://www.idtheftcenter.org/magic-the-gathering-data-breach-caused-by-accidental-overexposure/"
        }}>{`https://www.idtheftcenter.org/magic-the-gathering-data-breach-caused-by-accidental-overexposure/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`FiraCode - `}<a parentName="li" {...{
          "href": "https://github.com/tonsky/FiraCode"
        }}>{`https://github.com/tonsky/FiraCode`}</a></li>
      <li parentName="ul">{`scrcpy: `}<a parentName="li" {...{
          "href": "https://github.com/Genymobile/scrcpy"
        }}>{`https://github.com/Genymobile/scrcpy`}</a></li>
      <li parentName="ul">{`vscode: `}<a parentName="li" {...{
          "href": "https://code.visualstudio.com/"
        }}>{`https://code.visualstudio.com/`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <p>{`Vi pratar om utmaningar som kommer.`}</p>
    <hr></hr>
    <h1>{`S01E02 - Fix typo`}</h1>
    <ul>
      <li parentName="ul">{`Släpptes: 2019-12-23`}</li>
    </ul>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Ny version av atom (tack bittin) `}<a parentName="li" {...{
          "href": "https://github.com/atom/atom/releases/tag/v1.42.0"
        }}>{`https://github.com/atom/atom/releases/tag/v1.42.0`}</a>{` `}</li>
      <li parentName="ul">{`W3C har publicerat tre förslag på WebAssembly-specifikationer `}<a parentName="li" {...{
          "href": "https://www.w3.org/blog/news/archives/8123"
        }}>{`https://www.w3.org/blog/news/archives/8123`}</a></li>
      <li parentName="ul">{`WireGuard VPN schemalagd för mainline Linux Kernel 5.6 `}<a parentName="li" {...{
          "href": "https://lists.zx2c4.com/pipermail/wireguard/2019-December/004704.html"
        }}>{`https://lists.zx2c4.com/pipermail/wireguard/2019-December/004704.html`}</a></li>
      <li parentName="ul">{`Windows 7 slutar supportas och få uppdateringar den 14e januari 2020. Vivaldi browser-utvecklare uppmanar att byta till linux istället för att uppgradera till Win 10 `}<a parentName="li" {...{
          "href": "https://vivaldi.com/tr/blog/replace-windows-7-with-linux/"
        }}>{`https://vivaldi.com/tr/blog/replace-windows-7-with-linux/`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Facebook erkänner officiellt att de sparar location data även om man har stängt av det i appen `}<a parentName="li" {...{
          "href": "https://9to5mac.com/2019/12/18/facebook-accesses-your-location/"
        }}>{`https://9to5mac.com/2019/12/18/facebook-accesses-your-location/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`publiccode.eu : "Public money? Public code!" - Tysklands Styrande Parti CDU slår fast `}<a parentName="li" {...{
          "href": "https://fsfe.org/news/2019/news-20191125-01.en.html"
        }}>{`https://fsfe.org/news/2019/news-20191125-01.en.html`}</a></li>
      <li parentName="ul">{`joeycastillo bygger en open source (hårdvara och mjukvara) e-reader: `}<a parentName="li" {...{
          "href": "https://github.com/joeycastillo/The-Open-Book"
        }}>{`https://github.com/joeycastillo/The-Open-Book`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.gatsbyjs.org/"
        }}>{`https://www.gatsbyjs.org/`}</a>{` - open source-ramverk för att bygga websiter och appar`}</li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">{`Alex har funderat på vilka appar och tjänster han använder i vardagen på mobilen inför PinePhone-utmaningen.`}</li>
      <li parentName="ul">{`Seb funderar på Elementary Os som första linux-upplevelse. Vad tycker alex?`}</li>
    </ul>
    <hr></hr>
    <h1>{`S01E03 - Tillbaka till Framtiden`}</h1>
    <ul>
      <li parentName="ul">{`Släpptes: 2019-12-30`}</li>
    </ul>
    <h2>{`Återblickar`}</h2>
    <ul>
      <li parentName="ul">{`Smart Assistants`}</li>
      <li parentName="ul">{`Självkörande och elektriska bilar`}</li>
      <li parentName="ul">{`AR`}<ul parentName="li">
          <li parentName="ul">{`Funnits sedan 60-talet`}</li>
          <li parentName="ul">{`Pokemon GO`}</li>
          <li parentName="ul">{`Google Glass`}</li>
          <li parentName="ul">{`IKEAs "Place"-app`}</li>
        </ul></li>
      <li parentName="ul">{`VR`}<ul parentName="li">
          <li parentName="ul">{`Oculus rift 2012 kickstarter`}</li>
        </ul></li>
      <li parentName="ul">{`Crowdfunding`}</li>
      <li parentName="ul">{`Biometric security`}</li>
      <li parentName="ul">{`Github`}</li>
      <li parentName="ul">{`THE CLOUUUUD`}</li>
      <li parentName="ul">{`Docker`}</li>
    </ul>
    <h2>{`Förutsägelser`}</h2>
    <ul>
      <li parentName="ul">{`AR`}</li>
      <li parentName="ul">{`THE CLOUUUUD`}</li>
      <li parentName="ul">{`Cybertruck, look & feel`}<ul parentName="li">
          <li parentName="ul">{`brutalism`}</li>
          <li parentName="ul">{`cyberpunk`}</li>
        </ul></li>
      <li parentName="ul">{`E-Sport`}</li>
      <li parentName="ul">{`Open Source`}<ul parentName="li">
          <li parentName="ul">{`Microsoft störst enskild contributor`}</li>
          <li parentName="ul">{`Microsoft köper canonical eller liknande`}</li>
          <li parentName="ul">{`Microsoft går över till linux-kärnan`}</li>
        </ul></li>
    </ul>
    <hr></hr>
    <h1>{`S02E01 - Trevlig fortsättning`}</h1>
    <ul>
      <li parentName="ul">{`Släpptes: 2020-01-06`}</li>
    </ul>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Twitter finansierar open source, decentraliserad standard för social media: `}<a parentName="li" {...{
          "href": "https://twitter.com/jack/status/1204766078468911106"
        }}>{`https://twitter.com/jack/status/1204766078468911106`}</a></li>
      <li parentName="ul">{`Amazon, Apple, Google, Zigbee Alliance startar arbetsgrupp för öppen standard av smarta prylar: `}<a parentName="li" {...{
          "href": "https://www.apple.com/newsroom/2019/12/amazon-apple-google-and-the-zigbee-alliance-to-develop-connectivity-standard/"
        }}>{`https://www.apple.com/newsroom/2019/12/amazon-apple-google-and-the-zigbee-alliance-to-develop-connectivity-standard/`}</a></li>
      <li parentName="ul">{`Mozilla byter till Matrix från IRC: `}<a parentName="li" {...{
          "href": "https://discourse.mozilla.org/t/synchronous-messaging-at-mozilla-the-decision/50620"
        }}>{`https://discourse.mozilla.org/t/synchronous-messaging-at-mozilla-the-decision/50620`}</a></li>
      <li parentName="ul">{`DeepSpeech 0.6 släppt: `}<a parentName="li" {...{
          "href": "https://hacks.mozilla.org/2019/12/deepspeech-0-6-mozillas-speech-to-text-engine/"
        }}>{`https://hacks.mozilla.org/2019/12/deepspeech-0-6-mozillas-speech-to-text-engine/`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Y2k20 bug: `}<a parentName="li" {...{
          "href": "https://newyork.cbslocal.com/2020/01/02/parking-meters-nyc-credit-cards/"
        }}>{`https://newyork.cbslocal.com/2020/01/02/parking-meters-nyc-credit-cards/`}</a></li>
      <li parentName="ul">{`Google regionslås för podcasts (och andra begränsningar i andra tjänster?)`}</li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Home Assistant: `}<a parentName="li" {...{
          "href": "https://www.home-assistant.io/"
        }}>{`https://www.home-assistant.io/`}</a></li>
      <li parentName="ul">{`Open-source-illustrationer: `}<a parentName="li" {...{
          "href": "https://undraw.co/"
        }}>{`https://undraw.co/`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">{`Alex PinePhone kommer snart... troligen`}</li>
      <li parentName="ul">{`Seb funderar på linux...`}</li>
    </ul>
    <hr></hr>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Cursed Intro - Dark Dramatic Epic Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`License for all: CC BY (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      